import React from 'react';
import ReactDOM from 'react-dom/client';
import '@geoapify/geocoder-autocomplete/styles/minimal.css';
import './index.css';
import App from './components/App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <App/>
);
